<template>
  <PersonListing/>
</template>
<script>
import PersonListing from '@/components/persons/PersonListing'

export default {
  name: 'DefinitionsHome',
  components: {
    PersonListing
  },
}
</script>