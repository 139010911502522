<template>
  <div>
    <div class="text-center">
      <v-progress-circular
        :size="20"
        color="primary"
        indeterminate
        v-if="loading"
      ></v-progress-circular>
    </div>
    <v-list dense v-if="authoredDocuments.length>0">
      <v-subheader>Authored</v-subheader>
      <v-list-item-group color="primary">
        <v-list-item 
          v-for="(document, i) in authoredDocuments" 
          :key="i.documentid" 
          link 
          @click="$router.push({ name: 'document_home', params: { documentid: document.documentid.documentid } })"
        >
          <v-list-item-icon>
            <v-icon>{{ documentItemType(document.documentid.documenttypeid.documenttypeid) }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="document.documentid.englishtitle"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <div v-else class="text-caption pa-2">
      No Authored items
    </div>
  </div>
</template>

<script>
import configs from '@/configs'
import DocAdmin from '@/store/data'
import types from '@/helpers/types'

export default {
  name: 'PersonAuthored',
  components: {},
  props: {
    personId: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    connectionType: configs.connectionProperties.types,
    loading: true,
    authoredDocuments: []
  }),
  created() {
    this.getDocumentsByPerson()
  },
  methods: {
    documentItemType (documentItemTypeId) {
      return types.getDocumentTypeById(documentItemTypeId) ? types.getDocumentTypeById(documentItemTypeId).icon : 'mdi book-open-page-variant-outline'
    },
    async getDocumentsByPerson () {

      const documents = await DocAdmin.documentAuthors.list({ params: { personid: this.personId } })
      this.authoredDocuments = documents.data.results
      this.loading = false
    }
  }
}
</script>