<template>
  <div v-if="!loading">
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-card flat class="mx-auto">
          <ImageManager 
            :id="person.personid"
            :connection-type-id="connectionType.persons"
            allowUpload
          />
          <v-card-title>
            <strong>{{person.englishname}}</strong>
          </v-card-title>
          <v-card-text>
            <v-icon small>mdi-arrow-left</v-icon> 
            <router-link :to="{name: 'persons_landing' }">back to persons</router-link>
          </v-card-text>
          <v-card-actions small>
            <v-btn color="blue lighten-2" text @click="showReference = !showReference" small>
              <v-icon size="10" class="mr-1">mdi-asterisk</v-icon>  References
            </v-btn>
            <v-btn color="blue lighten-2" text @click="showAuthors = !showAuthors" small>
              <v-icon size="10" class="mr-1">mdi-newspaper-variant-outline</v-icon>  Authored
            </v-btn>
          </v-card-actions>

          <v-expand-transition>
            <Connections 
              v-if="showReference"
              :source-id="person.personid" 
              :connection-type-id="connectionType.persons" 
              :showNoItemsMessage="true"
              class="pl-2 pr-2"
            />
            <PersonAuthored v-if="showAuthors" :person-id="person.personid" />
          </v-expand-transition>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" md="8" v-if="personTranslations && personTranslations.length">
        <v-tabs v-model="tabs">
          <v-tab
            v-for="personTranslation in personTranslations"
            :key="personTranslation.persontranslationid"
          >
            {{ languageNameById(personTranslation.languageid) }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs">
          <v-tab-item
            v-for="personTranslation in personTranslations"
            :key="personTranslation.persontranslationid"
          >
            <v-card flat tile>
              <v-card-title class="text-h5">{{ personTranslation.name }}</v-card-title>
              <v-card-text v-html="personTranslation.body"></v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
      <v-col cols="12" sm="6" md="8" v-else>
        <v-card flat>
          <v-card-text>No translations found.</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <debug class="pt-4" v-if="debug">
      <template slot="sub-heading">PersonsPage</template>
      <template slot="info">
        person={{ person }}<br/>
        similarPersons={{ similarPersons }}<br/>
        personTranslations={{ personTranslations }}
      </template>
    </debug>

  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import DocAdmin from '@/store/data'
import Vue from 'vue'
import Debug from '@/components/common/Debug'
import configs from '@/configs'
import Connections from '@/components/connections/Connections'
import ImageManager from '@/components/ImageManager'
import PersonAuthored from '@/components/persons/PersonAuthored'

export default {
  name: 'PersonsPage',
  components: {
    Connections,
    Debug,
    ImageManager,
    PersonAuthored
  },
  data: () => ({
    connectionType: configs.connectionProperties.types,
    person: undefined,
    personTranslations: [],
    similarPersons: undefined,
    loading: true,
    tabs: null,
    showReference: false,
    showAuthors: false
  }),
  computed: {
    ...mapGetters('languages', ['languageNameById']),
    ...mapGetters('app', ['mainDrawer']),
    ...mapState('languages', ['languages']),
    ...mapState('app', ['debug'])
  },
  async created() {
    if (!this.languages) {
      await this.$store.dispatch('languages/getLanguages')
    }
    this.getPerson()
  },
  methods: {
    async getPerson(){
      const person = await DocAdmin.persons.get(this.$route.params.personid)
      this.person = person.data

      if (!this.person.personid) {
        console.error(`PersonsPage.getPerson no person found.`)
        return
      }

      // get person Translations
      const personTranslations = await DocAdmin.personTranslations.list({params: { personid: this.person.personid }})
      this.personTranslations = personTranslations.data.results

      // get similar persons
      const persons = await DocAdmin.persons.list({params: { limit: 10, search: this.person.englishname.slice(1, -1) }})
      this.similarPersons = persons.data.results
    
      this.loading = false
    },
  }
}
</script>
